import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/lib/metadata/metadata-boundary.js");
